import { Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { compareVersions } from 'src/helpers';
import { environment } from '../environments/environment';
import { OneSignalAdditionalDataType } from './enums/onesignal-data';
import { OneSignalAdditionalData } from './interfaces/onesignal-data';
import { AuthService } from './services/auth.service';
import { BuildService } from './services/build.service';
import { ControllerService } from './services/controller.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  isRequireUpdateApp = false;

  store = 'Store';

  constructor(
    private zone: NgZone,
    private platform: Platform,
    private market: Market,
    private screenOrientation: ScreenOrientation,
    private authService: AuthService,
    private buildService: BuildService,
    private controllerService: ControllerService,
    private router: Router,
  ) {
    this.initializeApp();
  }

  async ngOnDestroy() {
    await Network.removeAllListeners();
  }

  async onOpenStore() {
    const { id } = await App.getInfo();

    // await this.market.open(id);

    if (this.platform.is('android')) {
      await this.market.open(environment.androidAppId);
    } else if (this.platform.is('ios')) {
      await this.market.open(environment.iosAppId);
    }
  }

  private async initializeApp() {
    await this.platform.ready();

    if (Capacitor.isNativePlatform()) {
      //DEEPLINK
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          const slug = event.url.split(".co.id").pop();
          if (slug) {
            this.router.navigateByUrl(slug);
          }
        });
      });

      this.checkForUpdate();

      if (this.platform.is('android')) {
        await this.lockScreenOrientation();
      }

      await this.setStatusBar();

      this.initOneSignal();
    }

    this.handleBackButtonSubscription();

    await this.handleNetworkStatusChange();

    await this.handleAuthenticatedUser();

    await this.hideSplashScreen();
  }

  /**
   * Use build not version when we need to check for update
   */
  private checkForUpdate() {
    this.buildService.getMarketBuild(Capacitor.getPlatform()).subscribe(async marketBuild => {
      const { build: appBuild, version: appVersion } = await App.getInfo();

      // this.isRequireUpdateApp = +appBuild < +marketBuild;
      this.isRequireUpdateApp = compareVersions(appVersion, marketBuild);
      if (this.isRequireUpdateApp) {
        if (this.platform.is('android')) {
          this.store = 'Play Store';
        } else if (this.platform.is('ios')) {
          this.store = 'App Store';
        }
      }
    });
  }

  private async lockScreenOrientation() {
    await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }

  private async setStatusBar() {
    if (this.platform.is('android')) {
      await StatusBar.setStyle({
        style: Style.Dark,
      });
      await StatusBar.setBackgroundColor({
        color: '#ffffff',
      });
    }
  }

  private initOneSignal() {
    // uncomment to set one signal device logging to verbose when you faced trouble during debugging
    // OneSignal.setLogLevel(6, 0);

    OneSignal.setAppId(environment.oneSignal.appId);

    OneSignal.setNotificationWillShowInForegroundHandler(event => {
      // run data changes inside zone, because one signal emit all events outside angular zone
      // this needed in order to run angular or ionic code inside angular zone
      // see https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/241#issuecomment-349145994
      this.zone.run(() => {
        const notification = event.getNotification();

        event.complete(notification);
      });
    });

    OneSignal.setNotificationOpenedHandler(event => {
      // run data changes inside zone, because one signal emit all events outside angular zone
      // this needed in order to run angular or ionic code inside angular zone
      // see https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/241#issuecomment-349145994
      this.zone.run(() => {
        const { type, id } = event.notification.additionalData as OneSignalAdditionalData;

        if (!type || !id) {
          return;
        }

        if (type === OneSignalAdditionalDataType.Document) {
          this.router.navigateByUrl(`/tabs/detail-document/${id}`);
        }
      });
    });

    // if (this.platform.is('ios')) {
    // ios only to prompts the user for notification permissions
    OneSignal.promptForPushNotificationsWithUserResponse(async accepted => {
      // run data changes inside zone, because one signal emit all events outside angular zone
      // this needed in order to present alert inside angular zone
      // see https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/241#issuecomment-349145994
      await this.zone.run(async () => {
        // if (!accepted) {
        //   await this.controllerService.presentAlert(
        //     {
        //       header: 'Warning',
        //       message: 'Izin push notification ditolak, aplikasi tidak akan akan menerima push notification',
        //       buttons: ['OK'],
        //     },
        //     true,
        //   );
        // }
      });
    });
    // }
  }

  private handleBackButtonSubscription() {
    this.platform.backButton.subscribeWithPriority(-1, async () => {
      if (this.isRequireUpdateApp) {
        App.exitApp();
      } else if (!this.routerOutlet.canGoBack()) {
        await this.controllerService.presentAlert({
          header: 'Apakah anda yakin untuk keluar?',
          message: 'Aplikasi akan tertutup',
          buttons: [
            {
              text: 'Tidak',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Ya',
              handler: () => {
                App.exitApp();
              },
            },
          ],
        });
      }
    });
  }

  private async handleNetworkStatusChange() {
    // eager loading the alert module, if not eagerly loaded ionic will request alert to the server when offline
    await this.controllerService.presentAlert({
      animated: false,
      cssClass: 'transparent-alert',
      buttons: [],
    });

    // dismiss the eagerly loaded alert
    await this.controllerService.dismissAlert();

    Network.addListener('networkStatusChange', async status => {
      if (!status.connected) {
        await this.controllerService.presentAlert(
          {
            header: 'Warning',
            message: 'Koneksi internet terputus, harap periksa koneksi internet anda!',
            buttons: ['OK'],
          },
          true,
        );
      }
    });
  }

  private async handleAuthenticatedUser() {
    if (await this.authService.isAuthenticated()) {
      this.authService.getUser().subscribe();
    }
  }

  private async hideSplashScreen() {
    await SplashScreen.hide();
  }
}
