import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import id from '@angular/common/locales/id';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Market } from '@awesome-cordova-plugins/market/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProviders } from './utils/interceptors';
import { environment } from '../environments/environment';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';

registerLocaleData(id);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonIcon: 'chevron-back',
      backButtonText: '',
      infiniteLoadingSpinner: 'circular',
      loadingSpinner: 'circular',
      refreshingSpinner: 'circular',
      spinner: 'circular',
    }),
    AppRoutingModule,
    QuicklinkModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.currency,
    },
    {
      provide: LOCALE_ID,
      useValue: environment.locale,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    httpInterceptorProviders,
    QuicklinkStrategy,
    Market,
    ScreenOrientation,
    MediaCapture
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
