export const openLinkNewTab = (url: string) => {
  window.open(url, '_system', 'noopener noreferrer');
};
export const ArrayUniqueByKey = (data: any[], key: string) => [...new Map(data.map(item => [item[key], item])).values()];

export function compareVersions(version1, version2) {
  var components1 = version1.split('.');
  var components2 = version2.split('.');

  var maxLength = Math.max(components1.length, components2.length);

  var paddedComponents1 = components1.map(function (component) {
    return component.padStart(maxLength, '0');
  });
  var paddedComponents2 = components2.map(function (component) {
    return component.padStart(maxLength, '0');
  });

  var joinPaddedComponents1 = paddedComponents1.join('.');
  var joinPpaddedComponents2 = paddedComponents2.join('.');

  return joinPaddedComponents1 < joinPpaddedComponents2;
}
