import { FormGroup } from '@angular/forms';
import { FormControlOption } from '../../interfaces/form-control-option';

export class ValidationError extends Error {
  constructor(private errors: { [serverField: string]: any[] }) {
    super();

    // set the first validation error message from the first laravel error bag as error
    const error = Object.values(errors)[0][0];

    this.message = this.resolveMessage(error);
  }

  getErrors() {
    return this.errors;
  }

  render(formGroup: FormGroup, formControlOptions: Record<string, FormControlOption>) {
    for (const clientField in formControlOptions) {
      if (formControlOptions.hasOwnProperty(clientField)) {
        const formControlOption = formControlOptions[clientField];

        if (formControlOption.serverField) {
          const serverErrors = this.errors[formControlOption.serverField];

          if (serverErrors) {
            formGroup.get(formControlOption.formControlName || clientField).setErrors({
              serverErrors,
            });
          }
        }
      }
    }
  }

  private resolveMessage(error: any) {
    if (typeof error === 'object') {
      return this.resolveMessage(Object.values(error)[0]);
    }

    return error;
  }
}
