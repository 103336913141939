import { Injectable } from '@angular/core';
import {
  LoadingController,
  ToastController,
  AlertController,
  LoadingOptions,
  ToastOptions,
  AlertOptions,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ControllerService {
  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
    private alertController: AlertController,
  ) {}

  /**
   * @author Steven Evan R
   * @desc Create default alert
   */
  public async presentAlert(
    { header = '', message = '', buttons = ['OK'], backdropDismiss = false, ...rest }: AlertOptions,
    dismissAlertFirst: boolean = false,
  ): Promise<void> {
    if (dismissAlertFirst) {
      await this.dismissAlert();
    }
    const alert = await this.alertController.create({
      header,
      message,
      mode: 'ios',
      buttons,
      backdropDismiss,
      ...rest,
    });
    return await alert.present();
  }

  /**
   * @author Steven Evan R
   * @desc Create default toast
   */
  public async presentToast(
    { message = '', duration = 1000, ...rest }: ToastOptions,
    dismissToastFirst: boolean = false,
  ): Promise<void> {
    if (dismissToastFirst) {
      await this.dismissToast();
    }
    const toast = await this.toastController.create({
      message,
      duration,
      mode: 'ios',
      ...rest,
    });
    return await toast.present();
  }

  /**
   * @author Steven Evan R
   * @desc Create default loading
   */
  public async presentLoading(
    { message = '', duration = 0, backdropDismiss = false, spinner = 'bubbles', ...rest }: LoadingOptions,
    dismissLoadingFirst: boolean = false,
  ): Promise<void> {
    if (dismissLoadingFirst) {
      await this.dismissLoading();
    }
    const loading = await this.loadingController.create({
      backdropDismiss,
      duration,
      message,
      mode: 'ios',
      spinner,
      ...rest,
    });
    return await loading.present();
  }

  /**
   * @author Steven Evan R
   * @desc Dismiss alert loading and toast
   */
  public async dismissAll(): Promise<void> {
    await this.dismissAlert();
    await this.dismissLoading();
    await this.dismissToast();
  }

  /**
   * @author Steven Evan R
   * @desc Dismiss loading
   */
  public async dismissLoading(): Promise<void> {
    const loading = await this.loadingController.getTop();
    if (loading) {
      await this.loadingController.dismiss();
    }
  }

  /**
   * @author Steven Evan R
   * @desc Dismiss alert
   */
  public async dismissAlert(): Promise<void> {
    const alert = await this.alertController.getTop();
    if (alert) {
      this.alertController.dismiss();
    }
  }

  /**
   * @author Steven Evan R
   * @desc Dismiss toast
   */
  public async dismissToast(): Promise<void> {
    const toast = await this.toastController.getTop();
    if (toast) {
      this.toastController.dismiss();
    }
  }
}
