import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Loader } from '@googlemaps/js-api-loader';
import * as ApiGeocoding from '../interfaces/api/geocoding';
import { environment } from '../../environments/environment';

const BASE_URL = 'https://maps.googleapis.com/maps/api';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  constructor(private http: HttpClient) {}

  async getGoogleMaps() {
    const [language, region] = environment.locale.split('-');

    const loader = new Loader({
      apiKey: environment.googleMaps.apiKey,
      language,
      region,
    });

    const google = await loader.load();

    return google.maps;
  }

  getStaticMapUrl(latitude: number, longitude: number) {
    const [language, region] = environment.locale.split('-');

    const url = new URL(`${BASE_URL}/staticmap`);

    url.searchParams.set('center', `${latitude},${longitude}`);
    url.searchParams.set('format', 'jpg');
    url.searchParams.set('markers', `color:red|${latitude},${longitude}`);
    url.searchParams.set('key', environment.googleMaps.apiKey);
    url.searchParams.set('language', language);
    url.searchParams.set('region', region);
    url.searchParams.set('size', '500x300');
    url.searchParams.set('zoom', '18');

    return url.href;
  }

  geocoding(address: string) {
    const [language, region] = environment.locale.split('-');

    return this.http.get<ApiGeocoding.ResponseBody>(`${BASE_URL}/geocode/json`, {
      params: {
        address,
        components: `country:${region}`,
        key: environment.googleMaps.apiKey,
        language,
        region,
      },
    });
  }

  reverseGeocoding(latitude: number, longitude: number) {
    const [language] = environment.locale.split('-');

    return this.http.get<ApiGeocoding.ResponseBody>(`${BASE_URL}/geocode/json`, {
      params: {
        key: environment.googleMaps.apiKey,
        language,
        latlng: `${latitude},${longitude}`,
      },
    });
  }
}
