import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Country } from '../interfaces/country';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  protected apiUrl = `${environment.baseUrl}/v1`;
  public defaultCountry: Country = null;
}
