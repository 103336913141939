import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './utils/guards/auth.guard';
import { GuestGuard } from './utils/guards/guest.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule),
    canLoad: [GuestGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [GuestGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
    canLoad: [GuestGuard],
  },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
  //   canLoad: [AuthGuard],
  // },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule),
  },
  {
    path: 'server-error',
    loadChildren: () => import('./pages/server-error/server-error.module').then(m => m.ServerErrorPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'setting-editprofile',
    loadChildren: () => import('./pages/setting-editprofile/setting-editprofile.module').then( m => m.SettingEditprofilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'setting-signature',
    loadChildren: () => import('./pages/setting-signature/setting-signature.module').then( m => m.SettingSignaturePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'setting-team',
    loadChildren: () => import('./pages/setting-team/setting-team.module').then( m => m.SettingTeamPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'setting-sharedoc',
    loadChildren: () => import('./pages/setting-sharedoc/setting-sharedoc.module').then( m => m.SettingSharedocPageModule)
  },
  {
    path: 'setting-shareddoc',
    loadChildren: () => import('./pages/setting-shareddoc/setting-shareddoc.module').then( m => m.SettingShareddocPageModule)
  },
  // {
  //   path: 'detailapprovesignrequest/:uuidDoc',
  //   loadChildren: () => import('./pages/detailapprovesignrequest/detailapprovesignrequest.module').then( m => m.DetailapprovesignrequestPageModule),
  // },
  {
    path: 'active_esign/:token',
    loadChildren: () => import('./pages/activeesign/activeesign.module').then( m => m.ActiveesignPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'verifkyc',
    loadChildren: () => import('./pages/verifkyc/verifkyc.module').then( m => m.VerifkycPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'verifkycrenewal',
    loadChildren: () => import('./pages/verifkycrenewal/verifkycrenewal.module').then( m => m.VerifkycrenewalPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
