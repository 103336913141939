// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app: 'App',
  androidAppId: 'com.ids.meteraiku',
  iosAppId: 'id6448777592',
  baseUrl: 'https://staging-api.meteraiku.co.id',
  hideEsign: false,
  currency: 'IDR',
  locale: 'id-ID',
  googleAuth: {
    clientId: '325209492418-c0mm19ofsn12ee5h3fa967g8tm87t2ip.apps.googleusercontent.com',
  },
  googleMaps: {
    apiKey: 'AIzaSyAtnIhqA6M1NsncKry4lHA1CEKW3so8HJQ',
  },
  oneSignal: {
    appId: '1ed766b6-87e4-48ac-8eab-90b67b607a44',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
