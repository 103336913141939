import { Injectable } from '@angular/core';
import * as ApiCity from '../../interfaces/api/city';
import { City } from '../../interfaces/city';

@Injectable({
  providedIn: 'root',
})
export class CityTransformer {
  transform(data: ApiCity.ResponseBody): City {
    return {
      id: data.nomor,
      name: data.name,
    };
  }
}
