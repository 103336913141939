import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { BaseService } from './base.service';
import * as ApiBuild from '../interfaces/api/build';

@Injectable({
  providedIn: 'root',
})
export class BuildService extends BaseService {
  private url = `${this.apiUrl}/version`;

  constructor(private http: HttpClient) {
    super();
  }

  getMarketBuild(platform: string) {
    const url = new URL(this.url);

    url.searchParams.set('os', platform);

    return this.http.get<ApiBuild.ResponseBody>(url.href).pipe(map(data => data.version));
  }
}
