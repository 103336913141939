import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { catchError, delay, EMPTY, finalize, from, map, Observable, retryWhen, switchMap, throwError } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ControllerService } from '../../services/controller.service';
import { ValidationError } from '../errors/validation-error';

const LOADING_REQUEST_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];

const MAXIMUM_RETRY = 1;

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private navController: NavController,
    private loadingController: LoadingController,
    private controllerService: ControllerService,
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (LOADING_REQUEST_METHODS.includes(request.method) &&
        !request.body?.no_loading &&
        !request.urlWithParams.includes('no_loading=true')) {
      this.controllerService.presentLoading(
        {
          cssClass: 'loading',
          message: 'Loading...',
        },
        true,
      );
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          return this.handleUnauthorized(request, next);
        }

        return throwError(() => err);
      }),
      retryWhen(err => {
        let retry = 1;

        return err.pipe(
          delay(1000),
          map(error => {
            if (retry++ === MAXIMUM_RETRY) {
              throw error;
            }

            return error;
          }),
        );
      }),
      catchError(async err => {
        if (isDevMode()) {
          console.log(err);
        }

        if(request.responseType === "arraybuffer"){
          throw err;
        }

        if (err instanceof HttpErrorResponse) {
          // on client error
          if (err.status.toString().startsWith('4')) {
            if (err.status === 400) {
              return await this.handleBadRequest(err);
            } else if (err.status === 404) {
              return await this.handleNotFound();
            } else if (err.status === 422) {
              await this.handleUnprocessableEntity(err);
            }
            // on server error
          } else if (err.status.toString().startsWith('5')) {
            return await this.handleServerError(err);
            // on unknown error (usually status 0 indicates disconnected from network)
          } else {
            return await this.handleUnknownError();
          }
        }

        await this.controllerService.presentAlert(
          {
            header: 'Error',
            message: 'Terjadi kesalahan pada aplikasi, hubungi customer service!',
          },
          true,
        );

        return EMPTY;
      }),
      finalize(async () => {
        await this.controllerService.dismissLoading();
      }),
    );
  }

  private async handleBadRequest(err) {
    await this.controllerService.presentAlert(
      {
        header: 'Error',
        message: err.error.message,
      },
      true,
    );

    return EMPTY;
  }

  private handleUnauthorized(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.authService.removeAccessToken()).pipe(
      switchMap(async () => {
        this.authService.unsetUser();

        await this.navController.navigateRoot('/login', {
          animated: true,
          animationDirection: 'forward',
        });

        await this.controllerService.dismissLoading();

        return EMPTY;
      }),
    );

    return this.authService.refreshToken().pipe(
      switchMap(response => {
        const clonedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${response.access_token}`,
          },
        });

        return next.handle(clonedRequest);
      }),
      catchError(async error => {
        await this.authService.removeAccessToken();

        this.authService.unsetUser();

        await this.navController.navigateRoot('/login', {
          animated: true,
          animationDirection: 'forward',
        });

        // dismiss all presented loading
        let loading = await this.loadingController.getTop();

        while (loading) {
          await loading.dismiss();

          loading = await this.loadingController.getTop();
        }

        return error;
      }),
    );
  }

  private async handleNotFound() {
    await this.router.navigateByUrl('/not-found');

    return EMPTY;
  }

  private async handleUnprocessableEntity(err) {
    const error = new ValidationError(err.error.errors);

    await this.controllerService.presentAlert(
      {
        header: 'Data tidak valid',
        message: error.message,
      },
      true,
    );

    throw error;
  }

  private async handleServerError(err) {
    const url = this.router.createUrlTree(['/server-error'], {
      queryParams: {
        code: err.status,
      },
    });

    await this.router.navigateByUrl(url);

    return EMPTY;
  }

  private async handleUnknownError() {
    await this.controllerService.presentAlert(
      {
        header: 'Error',
        message: 'Koneksi internet terputus, harap periksa koneksi internet anda!',
      },
      true,
    );

    return EMPTY;
  }
}
