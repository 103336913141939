import { Injectable } from '@angular/core';
import { GoogleMapsService } from '../../services/google-maps.service';
import { CityTransformer } from './city.transformer';
import * as ApiUser from '../../interfaces/api/user';
import { User } from '../../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UserTransformer {
  constructor(private googleMapsService: GoogleMapsService, private cityTransformer: CityTransformer) {}

  transform(data: ApiUser.ResponseBody): User {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      phone_code: data.phone_code,
      id_mh_country: data.id_mh_country,
      signature_url: data.signature_url,
      emet_balance: data.emet_balance,
      esign_balance: data.esign_balance,
      enterprise: data.enterprise,
      user_type: data.user_type,
      is_customer: data.is_customer,
      status_esign: data.status_esign,
      profile_picture: data.profile_picture,
      available_esign: data.available_esign,
      status_digital_certificate: data.status_digital_certificate,
      digital_stock_available: data.digital_stock_available,
      user_type_text: data.user_type_text
    };
  }
}
